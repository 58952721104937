@import "../node_modules/@basware/graviton-theme/dist/basware-all.css";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';

html {
    background-color: #ebebeb;
}

body {
    font-family: "Fira Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.outlet {
    flex-wrap: wrap;
    justify-content: center;
}


.mat-select-panel {
    width: max-content;
}

.data-field {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.empty-state-custom {
    background-color: rgba(44, 44, 44, .04);
    padding: 12px 24px 24px 24px !important;
    margin: -4px -24px -24px -24px !important;
}

.empty-state-custom .gt-lead-text {
    flex: 0 0 100%;
    font-weight: 500;
    margin-bottom: 12px;
}

.empty-state-custom .gt-row {
    color: rgba(0, 0, 0, .54);
    padding-left: 24px;
    padding-right: 24px;
}

.align-center {
    text-align: center;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.filterWidth {
    max-width: 432px;
}

.pl-48 {
    padding-left: 48px !important;
}

.pl-24 {
    padding-left: 24px;
}

.empty-state-custom {
    background-color: rgba(44, 44, 44, .04);
    padding: 12px 24px 24px 24px !important;
    margin: -4px -24px -24px -24px !important;
}

.empty-state-custom .gt-lead-text {
    flex: 0 0 100%;
    font-weight: 500;
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.loader-section-width{
    margin: 0 -24px;
    width: -webkit-fill-available !important;
}

.full-width {
    width: 100%;
}